<template>
  <div>
    <div class="vip_swiper">
      <p class="include">
        等级说明
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.5"
            cy="11.5"
            r="10.5"
            stroke="#989898"
            stroke-width="2"
          />
          <path
            d="M12.1796 4.43994C13.473 4.43994 14.4996 4.79328 15.2596 5.49994C16.0063 6.17994 16.3796 7.11994 16.3796 8.31994C16.3796 9.21328 16.1263 9.99328 15.6196 10.6599C15.393 10.9266 14.853 11.4399 13.9996 12.1999C13.573 12.5599 13.2596 12.9266 13.0596 13.2999C12.8063 13.7266 12.6796 14.2133 12.6796 14.7599V15.2199H11.0796V14.7599C11.0796 14.0933 11.1996 13.5133 11.4396 13.0199C11.7196 12.4199 12.393 11.6466 13.4596 10.6999C13.833 10.3266 14.0596 10.0866 14.1396 9.97994C14.5396 9.47328 14.7396 8.94661 14.7396 8.39994C14.7396 7.61328 14.513 6.99327 14.0596 6.53994C13.6063 6.07327 12.953 5.83994 12.0996 5.83994C11.0996 5.83994 10.3596 6.16661 9.87965 6.81994C9.43965 7.37994 9.21965 8.14661 9.21965 9.11994H7.63965C7.63965 7.70661 8.03298 6.58661 8.81965 5.75994C9.61965 4.87994 10.7396 4.43994 12.1796 4.43994ZM11.8796 16.6199C12.2396 16.6199 12.533 16.7333 12.7596 16.9599C12.9863 17.1866 13.0996 17.4666 13.0996 17.7999C13.0996 18.1466 12.9796 18.4333 12.7396 18.6599C12.4863 18.8866 12.1996 18.9999 11.8796 18.9999C11.5596 18.9999 11.273 18.8866 11.0196 18.6599C10.793 18.4333 10.6796 18.1466 10.6796 17.7999C10.6796 17.4533 10.793 17.1733 11.0196 16.9599C11.2463 16.7333 11.533 16.6199 11.8796 16.6199Z"
            fill="#989898"
          />
        </svg>
      </p>
      <swiper
        ref="mySwiper"
        :options="{
          ...leftTitle,
        }"
        class="swiper-container-left"
        style="z-index: 99;"
      >
        <swiper-slide v-for="(item, index) in levelList" :key="item.id">
          <div class="zoomSiper_img " :class="`zbg${index}`">
            <div class="level">
              <h2>{{ item.name }}</h2>
              <template>
                <i
                  v-if="
                    item.id ==
                      $store.getters.getInfo['19jiang_inviter_level_id']
                  "
                  >当前等级</i
                >
                <i
                  v-else-if="
                    item.id < $store.getters.getInfo['19jiang_inviter_level_id']
                  "
                  >当前高于该等级</i
                >
                <i v-else>暂未开通</i>
              </template>
            </div>
            <div class="info">
              <img :src="$store.getters.getInfo.member_avatar" />
              <div class="user">
                <h2>{{ $store.getters.getInfo.member_nickname }}</h2>
                <p v-if="$store.getters.getInfo.vip_end_time === 0">
                  有效期：永久
                </p>
                <p v-else>
                  有效期：{{ $store.getters.getInfo.vip_end_time | time }}
                </p>
              </div>
            </div>
            <div class="time">
              <p v-if="$store.getters.getInfo.vip_end_time === 0">
                有效期至：永久
              </p>
              <p v-else>
                有效期至：{{ $store.getters.getInfo.vip_end_time | time }}
              </p>
              <p>立即续费</p>
            </div>
          </div>
        </swiper-slide>

        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </div>

    <div class="vip_growUp">
      <div class="vip_title">
        <h2>我的会员成长</h2>
      </div>
      <div class="data">
        <p class="include">
          当前积分{{ point1 }} 还需{{ point2 }}升级为{{ level_name2 }}
        </p>
        <div class="eff">
          <i class="circular_act"></i>
          <i class="circular"></i>
          <div
            class="bar"
            :style="{
              width: barWidth + '%',
            }"
          ></div>
          <div class="block"></div>
        </div>
        <div class="name">
          <span>{{ level_name1 }}</span>
          <span>{{ level_name2 }}</span>
        </div>
      </div>
    </div>

    <div class="vip_RightsAndInterests">
      <div class="vip_title">
        <h2>您可享受以下特权</h2>
        <span
          @click="
            $router.push({
              name: 'VipDetails',
            })
          "
          >更多权益</span
        >
      </div>
      <ul class="ul">
        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <circle cx="42" cy="42" r="24" stroke="#C29647" stroke-width="3" />
            <path
              d="M40.2311 29.3527C40.9815 27.9304 43.0185 27.9304 43.7689 29.3527L46.4315 34.3992C46.7209 34.9477 47.2483 35.3309 47.8594 35.4366L53.4817 36.4094C55.0663 36.6836 55.6957 38.6209 54.5749 39.7741L50.5982 43.8658C50.166 44.3105 49.9645 44.9306 50.0528 45.5444L50.865 51.1922C51.0939 52.7839 49.4459 53.9812 48.0028 53.2716L42.8825 50.7539C42.326 50.4803 41.674 50.4803 41.1175 50.7539L35.9972 53.2716C34.5541 53.9812 32.9061 52.7839 33.135 51.1922L33.9472 45.5444C34.0355 44.9306 33.834 44.3105 33.4018 43.8658L29.4251 39.7741C28.3043 38.6209 28.9337 36.6836 30.5183 36.4094L36.1406 35.4366C36.7517 35.3309 37.2791 34.9477 37.5685 34.3992L40.2311 29.3527Z"
              stroke="#C29647"
              stroke-width="3"
            />
          </svg>
          <span>积分累计</span>
        </li>
        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <path
              d="M18 25H65V37.5C65 37.5 62 38.5 62 42C62 45.5 65 46.5 65 46.5V59H18V46.5C18 46.5 21 45.5 21 42C21 38.5 18 37.5 18 37.5V25Z"
              stroke="#C29647"
              stroke-width="3"
              stroke-linejoin="round"
            />
            <path
              d="M27 25.5V31.5"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M27 53V59"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M55 25.5V31.5"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M55 53V59"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M42.38 35.48V50.12H41.02V48.86H34.94V50.12H33.58V35.48H37.28V32.58H38.68V35.48H42.38ZM34.94 47.56H37.28V42.66H34.94V47.56ZM38.68 47.56H41.02V42.66H38.68V47.56ZM34.94 41.38H37.28V36.8H34.94V41.38ZM38.68 36.8V41.38H41.02V36.8H38.68ZM44.06 33.74H50.46V34.8C49.8467 36.96 49.2 38.9 48.52 40.62C49.9067 42.62 50.6067 44.26 50.62 45.54C50.62 46.5667 50.3733 47.2933 49.88 47.72C49.3467 48.1467 48.3067 48.36 46.76 48.36L46.34 46.9C46.9133 46.9667 47.3533 47 47.66 47C48.3533 46.96 48.7933 46.8467 48.98 46.66C49.1667 46.46 49.2667 46.0867 49.28 45.54C49.2667 44.3133 48.5333 42.6733 47.08 40.62C47.7467 38.98 48.38 37.12 48.98 35.04H45.44V51.02H44.06V33.74Z"
              fill="#C29647"
            />
          </svg>
          <span>包邮券</span>
        </li>

        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <rect
              x="20"
              y="22"
              width="44"
              height="34"
              rx="2"
              stroke="#C29647"
              stroke-width="3"
            />
            <path
              d="M25 63H60"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M35.5 39.366C34.8333 38.9811 34.8333 38.0189 35.5 37.634L45.25 32.0048C45.9167 31.6199 46.75 32.101 46.75 32.8708L46.75 44.1292C46.75 44.899 45.9167 45.3801 45.25 44.9952L35.5 39.366Z"
              stroke="#C29647"
              stroke-width="3"
            />
          </svg>

          <span>线上课</span>
        </li>

        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <path
              d="M24.1372 43.2402C23.7621 42.8651 23.5514 42.3564 23.5514 41.826L23.5517 25.5513C23.5517 24.4467 24.4472 23.5513 25.5517 23.5513L41.826 23.5514C42.3564 23.5514 42.8651 23.7621 43.2402 24.1372L68.7129 49.61C69.494 50.391 69.494 51.6574 68.7129 52.4384L52.4384 68.713C51.6573 69.494 50.391 69.494 49.6099 68.713L24.1372 43.2402Z"
              stroke="#C29647"
              stroke-width="3"
            />
            <circle
              cx="36.0518"
              cy="35.0513"
              r="5.5"
              stroke="#C29647"
              stroke-width="3"
            />
          </svg>
          <span>色料仪器9折</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { post } from "@get/http";
import "swiper/swiper.scss";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      barWidth: 0,
      point1: 0,
      point2: 0,
      level_name1: "",
      level_name2: "",
      levelList: [
        {
          name: "匠粉",
          id: 0,
        },
        {
          name: "小匠",
          id: 10,
        },
        {
          name: "匠人",
          id: 11,
        },
        {
          name: "匠大师",
          id: 12,
        },
      ],
      leftTitle: {
        // loop: true,
        // speed: 500,
        // autoplay: false,
        // slidesPerView: 3,
        slidesPerView: "auto",
        centeredSlides: true,
      },
    };
  },
  async created() {
    let data = await post("/lw.Member/upgrade");
    this.point1 = data.point1;
    this.point2 = data.point2;
    this.level_name1 = data.level_name1;
    this.level_name2 = data.level_name2;
    this.barWidth = parseInt((data.point1 / data.point2) * 100);
    console.log(this.$store.getters.getInfo);
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

// 左边
.swiper-container-left {
  position: relative;
  // background: #fff;
  // overflow: hidden;
  width: rem(750);
  height: rem(280);

  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: rem(280);
    margin: 0 auto;
    width: rem(650);
    // box-shadow: 0px rem(4) rem(4) rgba(0, 0, 0, 0.18);
    .zbg0 {
      background: url("~@image/vip/vip_0.jpg") no-repeat;
      background-size: 100%;
    }
    .zbg1 {
      background: url("~@image/vip/vip_1.jpg") no-repeat;
      background-size: 100%;
    }
    .zbg2 {
      background: url("~@image/vip/vip_2.jpg") no-repeat;
      background-size: 100%;
    }
    .zbg3 {
      background: url("~@image/vip/vip_3.jpg") no-repeat;
      background-size: 100%;
    }
    .zoomSiper_img {
      padding: 0 rem(50);
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      transition: 300ms;
      transform: scale(0.9);
      border-radius: rem(16);
      .level {
        display: flex;
        align-items: center;
        padding: rem(24) 0;
        h2 {
          background-image: linear-gradient(
            106.45deg,
            #f8a709 8.89%,
            #cb8700 93.79%
          );
          background-clip: text;
          -webkit-background-clip: text;
          text-fill-color: transparent;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
          font-size: rem(42);
          margin-right: rem(16);
        }
        i {
          background: rgba(0, 0, 0, 0.2);
          border-radius: rem(16) 0px;
          // width: rem(94);
          padding: 0 rem(8);
          box-sizing: border-box;
          height: rem(32);
          line-height: rem(32);
          text-align: center;
          color: #ffffff;
          font-size: rem(20);
        }
      }
      .info {
        display: flex;
        img {
          margin-right: rem(10);
          width: rem(74);
          height: rem(74);
          border-radius: 50%;
          border: rem(2) solid rgba(255, 255, 255, 0.54);
        }
        .user {
          h2 {
            color: rgba(0, 0, 0, 0.7);
            font-size: rem(28);
            font-weight: bold;
          }
          p {
            color: rgba(0, 0, 0, 0.4);
            font-size: rem(24);
          }
        }
      }
      .time {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px rem(24) rem(24);
        width: 100%;
        height: rem(54);
        align-items: center;
        padding: 0 rem(42) 0 rem(50);
        box-sizing: border-box;
        p {
          color: #ffffff;
          font-size: rem(24);
          &:first-of-type {
            flex: 1;
          }
          &:last-of-type {
            background: url("~@image/vip/more_2.png") no-repeat center right;
            background-size: rem(8) rem(16);
            padding-right: rem(18);
          }
        }
      }
    }
  }

  .swiper-slide-active {
    // transform: scale(1);
    // margin: 0 rem(20);
    .zoomSiper_img {
      transform: scale(1);
    }
    .zoomSiper_zoom {
      top: rem(10);
      right: rem(0);
      width: rem(40);
    }
  }
}

.vip_swiper {
  background: #fbf4e6;
  width: rem(750);
  height: rem(416);
  margin: 0 auto;
  .include {
    padding: rem(34) rem(30);
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #989898;
    font-size: rem(20);
    svg {
      margin-left: rem(6);
      width: rem(22);
      height: rem(22);
    }
  }
}

.vip_title {
  display: flex;
  align-items: center;
  padding: rem(40) rem(24) rem(20);
  h2 {
    font-weight: bold;
    color: #222222;
    font-size: rem(30);
    flex: 1;
  }
  span {
    color: #989898;
    background: url("~@image/vip/more.png") no-repeat right center;
    background-size: rem(8) rem(16);
    padding-right: rem(18);
  }
}

.vip_growUp {
  border-radius: rem(16);
  background: #fff;

  margin: 0 auto;
  padding-bottom: rem(40);
  .data {
    background: #fffcf1;
    width: rem(690);
    height: rem(152);
    padding: rem(24) rem(30);
    margin: 0 auto;
    box-sizing: border-box;
    .name {
      display: flex;
      span {
        flex: 1;
        color: #989898;
        font-size: rem(24);
        &:last-of-type {
          text-align: right;
        }
      }
    }
    .include {
      color: #666666;
      font-size: rem(24);
    }
    .eff {
      position: relative;
      margin: rem(18) auto rem(10);
      .bar {
        transition-timing-function: linear;
        transition: 1.3s;
        position: absolute;
        width: 0;
        margin: auto;
        position: absolute;
        bottom: 0;
        top: 0;
        z-index: 8;
        left: 0;
        border-radius: rem(4);
        background: linear-gradient(90deg, #ebc586 0.63%, #ebb686 92.29%);
      }
      .block {
        width: rem(630);
        height: rem(8);
        background: #f1edde;
      }
      .circular {
        z-index: 9;
        width: rem(12);
        height: rem(12);
        border-radius: 50%;
        background: #efe8cf;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        margin: auto;
      }
      .circular_act {
        z-index: 9;
        width: rem(12);
        height: rem(12);
        border-radius: 50%;
        background: #e8ab6f;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        margin: auto;
      }
    }
  }
}

.vip_RightsAndInterests {
  background: #fff;
  margin-top: rem(24);
  padding-bottom: rem(56);
  ul {
    display: flex;
    padding-top: rem(20);
    li {
      flex: 1;
      span {
        display: block;
        color: #666666;
        font-size: rem(28);
        text-align: center;
        margin-top: rem(16);
      }

      svg {
        margin: 0 auto;
        width: rem(84);
        height: rem(84);
        display: block;
      }
    }
  }
}
</style>
